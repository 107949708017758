export default {
  okText: 'Xác nhận',
  closeText: 'Đóng',
  cancelText: 'Hủy',
  loadingText: 'Đang tải...',
  saveText: 'Lưu',
  delText: 'Xóa',
  resetText: 'Cài đặt lại',
  searchText: 'Tìm kiếm',
  queryText: 'Tìm ngay',
  nextText: 'Bước tiếp theo',

  inputText: 'Hãy nhập',
  chooseText: 'Hãy chọn',

  add: 'Thêm',
  update: 'Cập nhật',
  refresh: 'Làm mới',
  back: 'Quay lại',

  light: 'Chủ đề màu sáng',
  dark: 'Chủ đề hắc ám',
  countdown: {
    normalText: 'Nhận mã xác nhận',
    sendText: 'Sau {0} giây nhận lại',
  },
};
